var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{staticClass:"h-100",attrs:{"show":_vm.loading}},[_c('fsc-simple-card',{staticClass:"h-100"},[_c('template',{slot:"header"},[_c('h5',{staticClass:"my-10 text-fuscous-gray-500 font-size-16 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.headerTitle)+" ")])]),_c('b-form',{staticClass:"row",on:{"submit":_vm.onSubmit}},[_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("calendar.form_date"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.dueDate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('Datepicker',{attrs:{"input-class":{ 'is-invalid': invalid },"label-no-date-selected":_vm.$t('calendar.label_no_date'),"label-help":_vm.$t('calendar.label_help'),"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.dueDate),callback:function ($$v) {_vm.dueDate=$$v},expression:"dueDate"}})}}])})],1),_c('b-col',{staticClass:"font-size-14 form-group",attrs:{"sm":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t("general.title"))+" *")]),_c('validation',{attrs:{"validations":_vm.$v.subject},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.subject),expression:"subject"}],staticClass:"form-control",class:{ 'is-invalid': invalid },attrs:{"type":"text","disabled":_vm.hasNotPermissionWrite},domProps:{"value":(_vm.subject)},on:{"keyup":_vm.subjectRemainingCharCount,"input":function($event){if($event.target.composing){ return; }_vm.subject=$event.target.value}}})}}])}),(_vm.subject && _vm.subject.length > 0)?_c('span',[_vm._v(_vm._s(_vm.subjectRemainingText))]):_vm._e()],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("roles.description"))+" ")]),_c('validation',{attrs:{"validations":_vm.$v.task},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-textarea',{class:{ 'is-invalid': invalid },attrs:{"rows":"3","disabled":_vm.hasNotPermissionWrite},on:{"keyup":_vm.taskRemainingCharCount},model:{value:(_vm.task),callback:function ($$v) {_vm.task=$$v},expression:"task"}})}}])}),(_vm.task && _vm.task.length > 0)?_c('span',{staticClass:"font-size-14"},[_vm._v(_vm._s(_vm.taskRemainingText))]):_vm._e()],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("general.author"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.author},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-multiselect',{class:{ multiselect__invalid: invalid },attrs:{"options":_vm.instructors,"label":"name","getOptionLabel":_vm.instructorCustomLabel,"appendToBody":true,"clearable":false,"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.author),callback:function ($$v) {_vm.author=$$v},expression:"author"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("sidebar.instructors"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.user},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-multiselect',{class:{ multiselect__invalid: invalid },attrs:{"options":_vm.instructors,"label":"name","getOptionLabel":_vm.instructorCustomLabel,"appendToBody":true,"clearable":false,"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("general.customer"))+" ")]),_c('fsc-multiselect',{attrs:{"searchable":true,"options":_vm.students,"label":"name","getOptionLabel":_vm.studentCustomLabel,"appendToBody":true,"clearable":false,"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.student),callback:function ($$v) {_vm.student=$$v},expression:"student"}})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"5"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("general.status"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.status},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-multiselect',{class:{ multiselect__invalid: invalid },attrs:{"options":_vm.statuses,"label":"name","appendToBody":true,"clearable":false,"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})}}])})],1),_c('b-col',{staticClass:"form-group pl-0",attrs:{"sm":"5"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("general.priority"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.priority},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-multiselect',{class:{ multiselect__invalid: invalid },attrs:{"options":_vm.priorities,"label":"name","appendToBody":true,"clearable":false,"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.priority),callback:function ($$v) {_vm.priority=$$v},expression:"priority"}})}}])})],1),_c('b-col',{staticClass:"form-group pl-0",attrs:{"sm":"2"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("general.group"))+" ")]),_c('fsc-multiselect',{attrs:{"options":_vm.groups,"label":"color","appendToBody":true,"clearable":false,"searchable":false,"disabled":_vm.hasNotPermissionWrite},scopedSlots:_vm._u([{key:"selected-option",fn:function(props){return _c('span',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"h-16 w-16 rounded-circle",class:{
                'border border-oxford-blue-400': _vm.isWhite(props.color),
              },style:({ backgroundColor: props.color })})])}},{key:"option",fn:function(props){return _c('span',{staticClass:"d-flex"},[_c('span',{staticClass:"h-16 w-16 rounded-circle",class:{
                'border border-oxford-blue-400': _vm.isWhite(props.color),
              },style:({ backgroundColor: props.color })})])}}]),model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}})],1)],1),_c('template',{slot:"footer"},[_c('div',{staticClass:"btn-container"},[_c('abort-button',{staticClass:"fsc-btn-border",attrs:{"label":""},on:{"click":function($event){return _vm.onAbort()}}}),_c('save-button',{staticClass:"ml-2 fsc-btn-border",attrs:{"label":_vm.$t('calendar.save_button'),"disabled":_vm.hasNotPermissionWrite,"loading":_vm.putLoading},on:{"click":_vm.onSubmit}})],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }