














































































































































import { Component } from "vue-property-decorator";
import Actions from "@/components/Actions.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import Table from "@/components/Table.vue";
import { namespace } from "vuex-class";
import EditButton from "@/components/Button/EditButton.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import TodoForm from "@/views/Todo/TodoForm.vue";
import { mixins } from "vue-class-component";
import ViewSwitchMixin from "@/mixins/ViewSwitchMixin";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscCard from "@/components/Card/FscCard.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FilterAndSearch from "@/components/FilterAndSearch.vue";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import { IGroup } from "@/interfaces/IGroup";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { IStudent } from "@/interfaces/IStudent";
import BaseMixin from "@/mixins/BaseMixin";
import { EDIT } from "@/constants/FormType";
import { ITodo } from "@/interfaces/ITodo";
import { IInstructor } from "@/interfaces/IInstructor";
import FscMultiselect from "@/components/FscMultiselect.vue";
import FscModal from "@/components/Modal/FscModal.vue";
import FuncMixins from "@/mixins/FuncMixins";
import UserService from "@/services/UserService";
import DefaultInstructorSorting from "@/constants/DefaultInstructorSorting";

const TodoModule = namespace("todo");
const TodoFilterModule = namespace("todo/localFilter");
const InstructorModule = namespace("instructor");
const StudentModule = namespace("student");

@Component({
  components: {
    FscModal,
    FscMultiselect,
    FilterAndSearch,
    FscSimpleCard,
    FscCard,
    FscPageHeader,
    TodoForm,
    Table,
    Actions,
    CreateButton,
    EditButton,
    RemoveButton,
  },
})
export default class Todo extends mixins(ViewSwitchMixin, BaseMixin, FuncMixins) {
  public name = "Todo";

  protected deleteModalId = "delete-todo-modal";

  public fields = [
    {
      key: "createdOn",
      label: this.$t("general.created_on"),
      sortable: true,
    },
    {
      key: "dueDate",
      label: this.$t("general.due_date"),
      sortable: true,
    },
    {
      key: "status",
      label: this.$t("general.status"),
      sortable: true,
    },
    {
      key: "subject",
      label: this.$t("general.title"),
      sortable: true,
    },
    {
      key: "author.initials",
      label: this.$t("general.author"),
      sortable: true,
    },
    {
      key: "user.initials",
      label: this.$t("user.user"),
      sortable: true,
    },
    {
      key: "studentOrCostBearerName",
      label: this.$t("general.customer"),
      sortable: true,
    },
    {
      key: "priority.name",
      label: this.$t("general.priority"),
      sortable: true,
    },
    {
      key: "group",
      label: this.$t("general.group"),
      sortable: true,
    },
  ];
  protected filtered = "";

  @TodoFilterModule.State("filter")
  public todoLocalFilter: any;

  @TodoFilterModule.Mutation("SET_LOCAL_FILTER")
  public setTodoLocalFilter!: (payload: Record<any, any>) => void;

  // Todo
  @TodoModule.Action("filter")
  public filterAction: any;

  @TodoModule.Getter("getDataList")
  public items: any;

  @TodoModule.Getter("getTotal")
  public totalRows: any;

  @TodoModule.Action("findOne")
  public findOne: any;

  @TodoModule.Getter("getDataItem")
  public item: any;

  @TodoModule.Getter("getDataItemLoading")
  public dataItemLoading!: boolean;

  @TodoModule.Action("findPriorities")
  public findPriorities!: () => Promise<void>;

  @TodoModule.Getter("getPriorities")
  public priorities!: Array<any>;

  @TodoModule.Action("findStatuses")
  public findStatuses!: () => Promise<void>;

  @TodoModule.Getter("getStatuses")
  public statuses!: Array<IBasicNamedDTO>;

  @TodoModule.Action("findGroups")
  public findGroups!: () => Promise<void>;

  @TodoModule.Getter("getGroups")
  public groups!: Array<IGroup>;

  @TodoModule.Getter("getSuccess")
  public todoSuccess!: boolean;

  @TodoModule.Action("update")
  public updateTodo!: (options: ICrudOptions) => Promise<void>;

  @TodoModule.Action("create")
  public createTodo!: (options: ICrudOptions) => Promise<void>;

  @TodoModule.Action("updateStatusOrGroup")
  public updateStatusOrGroupAction!: (options: ICrudOptions) => Promise<void>;

  @TodoModule.Action("del")
  public delete: any;

  @TodoModule.Getter("getIsLoading")
  public isLoadingTodo!: boolean;

  // Instructor
  @InstructorModule.Action("filter")
  public filterInstructor!: (options: ICrudOptions) => Promise<void>;

  @InstructorModule.Getter("getDataList")
  public instructors!: Array<IInstructor>;

  // Student
  @StudentModule.Action("studentsByList")
  public filterStudents!: (filter: any) => Promise<void>;

  @StudentModule.Getter("getStudentsByList")
  public students!: Array<IStudent>;

  public todoDate = "";
  public instructor = null;
  protected query: Record<any, any> | null = null;
  protected groupClass = "w-75";

  public requestQuery(query: Record<any, any>): void {
    this.query = query;
    this.filterAction({
      resource: "todos",
      filter: {
        ...query,
        offset: 0,
        searchTerm: this.filtered,
        recipientId: this.instructor,
      },
    });
  }

  public async onRowClicked(ctx: any): Promise<void> {
    this.formType = EDIT;
    this.openForm();
    this.selectedRow = ctx;
    await this.findOne({
      resource: "todos",
      id: ctx.id,
    });
  }

  public get headerTitle(): any {
    return this.typeCreate() ? this.$t("todo.new_todo") : this.$t("todo.edit_todo");
  }

  public get dataItem(): any {
    return this.typeCreate() ? {} : this.item;
  }

  public fetchData() {
    this.findStatuses();
    this.findGroups();
    this.filterInstructor({
      resource: "instructor",
      filter: {
        archived: false,
        ...DefaultInstructorSorting,
      },
    });
    this.filterStudents({
      offset: 0,
      limit: 999999,
    });
    this.findPriorities();
  }

  public mounted(): void {
    this.fetchData();
    if (this.todoLocalFilter instanceof Object) {
      this.instructor = this.todoLocalFilter.recipientId;
      this.filtered = this.todoLocalFilter.searchTerm;
    } else {
      const instructor = UserService.getUser();
      this.instructor = instructor.id;
    }
  }

  protected onChangeFilter(): void {
    this.debouncer(() => {
      this.saveLocalFilter();
      if (this.query) {
        this.requestQuery(this.query);
      }
    });
  }

  protected onDelete(): void {
    if (!this.selectedRow) return;

    this.$bvModal.show(this.deleteModalId);
  }

  private async onSelectGroup(group: IGroup, todoId: number): Promise<void> {
    await this.updateStatusOrGroup(group, todoId, "group");
  }

  public async onSelectStatus(status: IBasicNamedDTO, todoId: number): Promise<void> {
    await this.updateStatusOrGroup(status, todoId, "status");
  }

  public async updateStatusOrGroup(newData: Record<any, any>, todoId: number, type: "status" | "group"): Promise<void> {
    await this.updateStatusOrGroupAction({
      resource: `todos/${todoId}/${type}`,
      data: newData.id,
    });

    if (this.todoSuccess && this.query) {
      this.requestQuery(this.query);
    }
  }

  public get instructorOptions(): Array<any> {
    return this.instructors;
  }

  protected get studentsOptions(): Array<IBasicNamedDTO> {
    return this.students;
  }

  protected async onSubmit(todo: ITodo): Promise<void> {
    if (this.typeCreate()) {
      await this.createTodo({
        resource: "todos",
        data: todo,
      });
    } else {
      await this.updateTodo({
        resource: "todos",
        data: todo,
        id: this.selectedRow.id,
      });
    }

    if (this.todoSuccess && this.query) {
      this.requestQuery(this.query);
      this.closeForm();
    }
  }

  private saveLocalFilter() {
    this.setTodoLocalFilter({ recipientId: this.instructor || null, searchTerm: this.filtered });
  }

  protected onFilterInstructor(instructorId: any): void {
    this.instructor = instructorId;
    this.saveLocalFilter();
    this.requestQuery({
      ...this.query,
      offset: 0,
    });
  }

  protected async deleteModalConfirmed(): Promise<void> {
    if (!this.selectedRow) return;

    await this.delete({
      resource: "todos",
      id: this.selectedRow.id,
    });

    if (this.query) {
      this.requestQuery(this.query);
    }
  }
}
